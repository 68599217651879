import { Route, Routes } from "react-router-dom";
import Error404 from "./components/Error";
import Home from "./container/Home";
import BlogDetailTEDx from "./components/BlogDetail";

function App() {
  return (
    <Routes >
   
    {/* <Route path="/nasil-tedx-organizatoru-olursun" element={<BlogDetailTEDx />} /> */}
    <Route path="/home" element={<Home />} />
    <Route path="/" element={<Home />} />
    <Route path="/*" element={<Error404 />} />
</Routes>
  );
}

export default App;
