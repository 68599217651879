import React from 'react'
import { Link } from 'react-router-dom'
import EventCard from './EventCard'
import events from '../data/events.json'

export default function BecomePartner() {
    function getEvents() {
        return events.filter(event => event.openToPartnership & (new Date(event.date) > new Date()))
    }
    return (


        <section class="bg-white dark:bg-gray-900">
            <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16">
                <h1 class="mb-4 text-3xl font-extrabold tracking-tight leading-none text-gray-900 md:text-3xl lg:text-4xl dark:text-white">Lise & Üniversite</h1>
                <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-400">CASVAL Academy, genç beyinlerin inşa ettiği bir gelecek için kendilerini programlarına davet ediyor. </p>
            </div>
            
        </section>
        

    )
}
