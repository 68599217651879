import React from 'react'
import Navbar from '../components/Navbar'
import Jumbotron from '../components/Jumbotron'
import Timeline from '../components/Timeline'
import BecomePartner from '../components/BecomePartner'
import Stats from '../components/Stats'
import BlogSection from '../components/BlogSection'
import Footer from '../components/Footer'
import Banner from '../components/Banner'


export default function Home() {
  return (
    <div>
        <Navbar/>
        <Jumbotron/>
        <Timeline/>
        <BecomePartner/>
        <Stats/>
        {/* <BlogSection/> */}
        <Footer/>
        {/* <Banner/> */}
        
    </div>
  )
}
